import React from 'react';
import { withRouter } from 'react-router-dom';
import $ from 'jquery'
import { Base64 } from 'js-base64';
import './index.scss';
var getdataUrl = 'https://pub1.socialcommit.com/api/v3/';
var Gourl = 'https://cloud.socialcommit.com';
// var Gourl = 'https://fbln.onesight.com';

class FbAuthorization extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            AddpageList:[],
            pageList:[],
            authStatus:false,//控制弹框显示隐藏
            AuthStatusCode:'',//获取code
            addPageStatus:false,//添加页面失败弹框
            addPageCode:'',//添加页面失败弹框
            accessTokenKey:'',
            ExistPage:[],
            pageObj:{},
            AddPageBtnStatus:true
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onChangeAdd = this.onChangeAdd.bind(this);
        this.CloseAuthFn = this.CloseAuthFn.bind(this);
        this.GoAddPage = this.GoAddPage.bind(this);
    }

    componentDidMount(){
        var {type,token,code,url,PageType,PageIcon,PageName,PageUserName} = this.props.match.params;
        var locationSearch = this.props.location.search;
        if(type === 'pagedetail'){ 
            var platform = decodeURIComponent(url).split('/')[3];
            var PageId = decodeURIComponent(url).split('/')[6];
            localStorage.setItem('PageAuthcode',code)
            localStorage.setItem('PageAuthtoken',token)
            localStorage.setItem('PageAuthurl',decodeURIComponent(url))
            localStorage.setItem('PageIcon',PageIcon)
            localStorage.setItem('PageName',PageName)
            localStorage.setItem('PageUserName',PageUserName)
            if(code && token && platform && PageId){
                window.location.href = (getdataUrl+'authorization/facebook/auth/redirect?code=' + code + '&token=' + token + '&platform=' + platform + '&page_id=' + PageId)
                return false;
            }
        }
        if(type === 'pageadd'){
            var platform = decodeURIComponent(url).split('/')[3];
            localStorage.setItem('PageType',PageType)
            localStorage.setItem('PageAuthcode',code)
            localStorage.setItem('PageAuthtoken',token)
            localStorage.setItem('PageAuthurl',decodeURIComponent(url))
            if(code && token && platform){
                window.location.href = (getdataUrl+'authorization/facebook?code=' + code + '&token=' + token + '&platform=' + platform )
                return false;
            }
        }
        //页面授权
        if(locationSearch.indexOf('state=') !== -1){
            var PageAuthcode = localStorage.getItem('PageAuthcode')
            var PageAuthtoken = localStorage.getItem('PageAuthtoken')
            var Authplatform = localStorage.getItem('PageAuthurl').split('/')[3];
            var SeacchStr = locationSearch.split('code=')[1];
            var authCode = SeacchStr.split('&state=')[0];
            var state = SeacchStr.split('&state=')[1];
            var PageIcon = localStorage.getItem('PageIcon')
            var PageName = localStorage.getItem('PageName')
            var PageUserName = localStorage.getItem('PageUserName');
            $.ajax({
                url:getdataUrl + 'authorization/facebook/page/add',
                type:'post',
                data:{
                    code:PageAuthcode,
                    token:PageAuthtoken,
                    auth_code:authCode,
                    platform:Authplatform,
                    state:state,
                },
                success:(res) =>{
                    var pageObj = this.state.pageObj;
                    pageObj['PageIcon'] = PageIcon;
                    pageObj['PageName'] = PageName;
                    pageObj['PageUserName'] = PageUserName;
                    this.setState({
                        pageObj
                    })
                    if(res.status_code === 200){
                        var OpenUrl = localStorage.getItem('PageAuthurl');
                        var Arr = OpenUrl.split('/');
                        Arr[9] = 1;
                        Arr = Arr.join('/');
                        localStorage.clear();
                        window.location.href = (Gourl + Arr)
                    }else if(res.status_code === 403){
                        this.setState({
                            authStatus:'You do not have permission and the authorization fails.',
                            AuthStatusCode:res.status_code
                        })
                    }else if(res.status_code === 20019){
                        this.setState({
                            authStatus:"You may have cancelled TEAM SIGHT LIMITEDsupport's ability to obtain authorization data on Facebook.",
                            AuthStatusCode:res.status_code
                        })
                    }else if(res.status_code === 200080){
                        this.setState({
                            authStatus:'The current home page has been authorized by other users, and a home page can only be authorized in one TEAM SIGHT LIMITEDsupport account.',
                            AuthStatusCode:res.status_code
                        })
                    }else{
                        this.setState({
                            authStatus:'An error link occurred and the authorization failed.'
                        })
                    }
                },error:(err) =>{
                    this.setState({
                        authStatus:true
                    })
                    console.log(err,'err');
                }
            })
            return false;
        }
        //添加页面
        if(locationSearch.indexOf('code=') != -1){
            var PageAuthcode = localStorage.getItem('PageAuthcode');
            var PageAuthtoken = localStorage.getItem('PageAuthtoken');
            var Authplatform = localStorage.getItem('PageAuthurl').split('/')[3];
            var auth_code = locationSearch.split('code=')[1];
            $.ajax({
                url:getdataUrl + 'authorization/facebook',
                type:'post',
                data:{
                    code:PageAuthcode,
                    token:PageAuthtoken,
                    auth_code:auth_code,
                    platform:Authplatform,
                },
                success:(res) =>{
                    if(res.status_code === 200){
                        if(Authplatform == 'facebook'){
                            this.setState({
                                accessTokenKey:res.access_token_key,
                                pageList:res.result
                            })
                        }else if(Authplatform === 'instagram'){
                            var arr = [];
                            res.result.map((v,i) => {
                                if(v.instagram_business_account){
                                    arr.push(v.instagram_business_account)
                                }
                            })
                            this.setState({
                                accessTokenKey:res.access_token_key,
                                pageList:arr
                            })
                        }
                        
                    }else if(res.status_code === 20019){
                        this.setState({
                            addPageStatus:"You may have cancelled TEAM SIGHT LIMITEDsupport's ability to obtain authorization data on Facebook.",
                            addPageCode:res.status_code
                        })
                    }else{
                        this.setState({
                            addPageStatus:'An error link occurred and the authorization failed.',
                            addPageCode:res.status_code
                        })
                    }
                },error:(err) =>{
                    console.log(err,'err');
                }
            })
        }
        
    }
    GoAddPage(){
        var PageType = localStorage.getItem('PageType');
        var AuthUrl = localStorage.getItem('PageAuthurl');
        if(PageType === 'analysis'){
            AuthUrl = AuthUrl.split('/');
            AuthUrl[5] = 'oneself-select';
            AuthUrl = AuthUrl.join('/');
            localStorage.clear();
            window.location.href = (Gourl + AuthUrl);
        }else if (PageType === 'publish') {
            localStorage.clear();
            window.location.href = (Gourl + '/content/publish/postmanage/addpage');
        } else if (PageType === 'dashboard') {
            localStorage.clear()
            window.location.href = (Gourl + '/data/dashboard/index');
        }
        
    }
    CloseAuthFn(){
        this.setState({
            authStatus:false,
        })

       window.location.href = ( Gourl + localStorage.getItem('PageAuthurl'))
    }
    handleInputChange(v,index){
        let {pageList} = this.state;
        var AddpageList = JSON.parse(JSON.stringify(this.state.AddpageList));
        var platform = localStorage.getItem('PageAuthurl').split('/')[3];

            
        pageList[index].checked = !pageList[index].checked;
        if(platform == 'facebook'){
            if(pageList[index].checked){
                AddpageList.push(v.id)
            }else{
                AddpageList.splice(index,1);
            }
        }else if(platform == 'instagram'){
            if(pageList[index].checked){
                AddpageList.push({name:v.username,id:v.id})
            }else{
                AddpageList.splice(index,1);
            }
        }
        
        this.setState({
            pageList,
            AddpageList,
        });
    }
    onChangeAdd(){
        if(this.state.AddPageBtnStatus === false){
            return false;
        }
        this.setState({
            AddPageBtnStatus:false
        })
        var PageAuthcode = localStorage.getItem('PageAuthcode')
        var PageAuthtoken = localStorage.getItem('PageAuthtoken');
        var AddpageList =JSON.parse(JSON.stringify(this.state.AddpageList));
        var AuthUrl = localStorage.getItem('PageAuthurl');
        var PageType = localStorage.getItem('PageType');
        var AuthPlatfrom = localStorage.getItem('PageAuthurl').split('/')[3];
        if(AddpageList.length === 0){
            return false;
        }
        if(AuthPlatfrom === 'facebook'){
            $.ajax({
                url:'https://api.socialcommit.com/api/v3/analysis/page/add',
                type:'POST',
                dataType: "json",
                data:{
                    'access_token_key':this.state.accessTokenKey,
                    'code':PageAuthcode,
                    'token':PageAuthtoken,
                    'page_id':AddpageList.join(','),
                    'platform':AuthPlatfrom,    
                },
                success:(res) =>{
                    this.setState({
                        AddPageBtnStatus:true
                    })
                    if(res.status_code === 200){
                        if(res.exist_page && res.exist_page.length>0){
                            this.setState({
                                addPageStatus:'The current profile has been authorized by other user. Profile can only be authorized in one TEAM SIGHT LIMITED account.',
                                addPageCode:'200080',
                                ExistPage:res.exist_page
                            })
                            return false;
                        }
                        if(PageType == 'analysis'){
                            AuthUrl = AuthUrl.split('/');
                            AuthUrl[5] = 'success-default';
                            AuthUrl = AuthUrl.join('/');
                            window.location.href = (Gourl + AuthUrl);
                        }else if (PageType === 'publish'){
                            window.location.href = (Gourl + '/content/publish/postmanage/addpage')
                        } else if (PageType === 'dashboard') {
                            window.location.href = (Gourl + '/data/dashboard/index')
                        }
                       
                    }else if(res.status_code === 200080){
                        this.setState({
                            addPageStatus:'The current profile has been authorized by other user. Profile can only be authorized in one TEAM SIGHT LIMITED account.',
                            addPageCode:res.status_code,
                            ExistPage:res.exist_page
                        })
                    }else{
                        this.setState({
                            addPageStatus:'An error link occurred and the authorization failed.',
                            addPageCode:res.status_code
                        })
                    }
                },error:(err) =>{
                    console.log(err,'err')
                }
            })
        }else if(AuthPlatfrom === 'instagram'){
            var AddpageId =[],
                AddpageName=[];
             AddpageList.map((v,i) =>{
                 AddpageId.push(v.id)
                 AddpageName.push(v.name)
            })
            
            $.ajax({
                url:'https://api.socialcommit.com/api/v3/analysis/page/add',
                type:'POST',
                dataType: "json",
                data:{
                    'code':PageAuthcode,
                    'token':PageAuthtoken,
                    'page_id':AddpageId.join(','),
                    'platform':AuthPlatfrom,    
                    'page_username':AddpageName.join(','),
                    'access_token_key':this.state.accessTokenKey
                },
                success:(res) =>{
                    this.setState({
                        AddPageBtnStatus:true
                    })
                    if(res.status_code === 200){
                        if(res.exist_page && res.exist_page.length>0){
                            this.setState({
                                addPageStatus:'The current profile has been authorized by other user. Profile can only be authorized in one TEAM SIGHT LIMITED account.',
                                addPageCode:'200080',
                                ExistPage:res.exist_page
                            })
                            return false;
                        }
                        if(PageType == 'analysis'){
                            AuthUrl = AuthUrl.split('/');
                            AuthUrl[5] = 'success-default';
                            AuthUrl = AuthUrl.join('/');
                            window.location.href = (Gourl + AuthUrl);
                        } else if (PageType === 'publish') {
                            window.location.href = (Gourl + '/content/publish/postmanage/addpage')
                        } else if (PageType === 'dashboard') {
                            window.location.href = (Gourl + '/data/dashboard/index')
                        }
                        
                    }else if(res.status_code === 200080){
                        this.setState({
                            addPageStatus:'The current profile has been authorized by other user. Profile can only be authorized in one TEAM SIGHT LIMITED account.',
                            addPageCode:res.status_code,
                            ExistPage:res.exist_page
                        })
                    }else if(res.status_code === 20019){
                        this.setState({
                            addPageStatus:"You may have cancelled TEAM SIGHT LIMITED's ability to obtain authorization data on Facebook.",
                            addPageCode:res.status_code
                        })
                    }else{
                        this.setState({
                            addPageStatus:'An error link occurred and the authorization failed.',
                            addPageCode:res.status_code
                        })
                    }
                },error:(err) =>{
                    console.log(err,'err')
                }
            })
        }
        
    }
    render () {
        var {pageList,authStatus,AuthStatusCode,addPageStatus,addPageCode,ExistPage,pageObj,AddPageBtnStatus} = this.state;
        return (
            <div className='AuthBox'>
            {pageList.length>0 ?
            <div className='AuthAddPage'>
                <div className='AuthAddPageCore'>
                    <div className='AuthAddPageCoreHeader'>
                        <div>
                            <h4>Add my profile by authorization </h4>
                            <p>
                                <i className='arrow_icon'></i> <span>Guide operational decisions with more precise authorization data.</span>
                            </p>
                            <p>
                                <i className='arrow_icon'></i> <span>Use content publishing products to reach users in time!</span>
                            </p>
                        </div>
                        <div>
                           
                        </div>
                    </div>
                    <div className='AuthAddPageCoreContent'>
                        <h4>Please select the profile you want to add to the profiles list.</h4>
                        <div className='AuthAddPageCoreContentMyPage'>
                            {
                                pageList.map((v,i)=>{
                                    return(

                                        <div className="oneself_mo2_select" key={i}>
                                            <p className="name">
                                                <label>
                                                    <input
                                                        className="labelInput"
                                                        type="checkbox"
                                                        readOnly
                                                        checked={v.checked?v.checked:false}
                                                        onChange={()=>{this.handleInputChange(v,i);}}
                                                    />
                                                    {v.name}
                                                </label>
                                            </p>
                                            <p className="username"><span>@{v.username?v.username:'--'}</span></p>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className="footer">
                        <div>
                            <span onClick={this.GoAddPage}>cancel</span>
                            <span className={AddPageBtnStatus === false ? 'OneSelfMo_Nobtn' : ''} onClick={this.onChangeAdd}>Add</span>
                        </div>
                    </div>
                </div>
            </div>
            :
            (authStatus === false || addPageStatus === false) &&
            <div className='loader_wrap'>
                <div className='loader'></div>
            </div>
            }
            {
                authStatus ?
                <div className='AuthorizedBox'>
                    <div className='AuthorizedBoxCore'>
                        <div className='AuthorizedBoxCoreHeader'>
                            <span>Connect With Facebook to get Insights</span>
                            <small>To analyze your audience deeply！</small>
                        </div>
                        <div className='AuthorizedBoxCoreInfo'>
                            <dl>
                                <dt>
                                    <img src={'https://store1.onesight.cn/icons/' + pageObj.PageIcon} alt='' />
                                </dt>
                                <dd>
                                    <span>{pageObj.PageName}</span>
                                    <span>@{pageObj.PageUserName}</span>
                                </dd>
                            </dl>
                        </div>
                        
                        <div className='AuthorizedBoxCoreContent'>
                            <div className='AuthorizedBoxCoreContentAuthError'>
                                <i className='iconfont iconClosex'></i>
                                <span>{authStatus}</span>
                                {
                                    AuthStatusCode === 20019 ?
                                    <div className='AuthErrorTooltip'>
                                        If you want to view the authorization data, please re-authorize it.<br/>And check "View Home Page Performance or Analysis" on Facebook.
                                    </div>
                                    :
                                    AuthStatusCode === 200080 ?
                                    <div className='AuthErrorTooltip'>
                                        <span>You can:</span>
                                        <span>1. Contact your account administrator who authorized this homepage to create a team sub-account for you.</span>
                                    </div>   
                                    :
                                    <div className='AuthErrorTooltip'>
                                        <span>You can:</span>
                                        <span>1、Go to your Facebook Public Page Home >Setting >Page Roles to confirm that current logged in account is Admin, Editor or other roles.</span>
                                    </div>                                    
                            }
                                
                                
                                <span className='gradient'></span>
                            </div>   
                        </div>                    
                        
                        <div className='AuthorizedBoxCoreContentFooter'>
                            <span className='big btn btn-have-shadow-blue btn-medium' onClick={this.CloseAuthFn}>OK</span>
                        </div>
                    </div>
                </div>
                :''
            }
            {  
                addPageStatus?
                <div className='authAddPageBox'>
                    <div className='authAddPageError'>
                        <div className='authAddPageErrorheader'>Failed to add page by authorization</div>
                        <div className='authAddPageErrormain'>
                            {
                                ExistPage.length === 1?
                                <div className='authAddPageErrormainInfo'>
                                    {
                                        ExistPage.map((v,i) =>{
                                            return <dl key={i}>
                                                <dt><img src={'https://store1.onesight.cn/'+v.page_image} alt='' /></dt>
                                                <dd>
                                                    <span>{v.page_name}</span>
                                                    <span>@{v.page_username}</span>
                                                </dd>
                                            </dl>
                                        })
                                    }
                            </div> 
                            : ''
                            }
                            {
                                ExistPage.length >1?
                                <div className='authAddPageErrormainInfos'>
                                    {
                                        ExistPage.map((v,i) =>{
                                            return <dl key={i}>
                                                <dt><img src={'https://store1.onesight.cn/'+v.page_image} alt='' /></dt>
                                                <dd>
                                                    <span>{v.page_name}</span>
                                                    <span>@{v.page_username}</span>
                                                </dd>
                                            </dl>
                                        })
                                    }
                                </div>
                                : ''
                            }
                            {/* */}
                            
                            <i className='iconfont iconClosex'></i>
                            <div className='authAddPageErrormainTitle'>Authorization to add home page failed<br/>{addPageStatus}</div>
                            {
                                addPageCode === 20019?
                                <div className='authAddPageErrormainTooltip'>
                                        If you want to view the authorization data, please re-authorize it.<br/>And check "View Home Page Performance or Analysis" on Facebook.
                                </div>
                                :
                                addPageCode === 200080 ?
                                <div className='authAddPageErrormainTooltip'>
                                    <span>You can:</span>
                                    <span>1. Contact your account administrator who authorized this homepage to create a team sub-account for you.</span>
                                </div>   
                                :
                                <div className='authAddPageErrormainTooltip'>
                                    <span>You can:</span>
                                    <span>1、Go to your Facebook Public Page Home >Setting >Page Roles to confirm that current logged in account is Admin, Editor or other roles.</span>
                                </div>
                            }   
                            
                             
                            <div className='authAddPageErrormainfooter'>
                                    <span onClick={this.GoAddPage}>OK</span>
                            </div>
                        </div>
                    </div>
                </div>
                :''
            }
        </div>
        )
    }
}
export default withRouter(FbAuthorization)