import React from 'react';
import { withRouter } from 'react-router-dom';
import './App.css';
import FacebookAuth from './FacebookAuth/index';

class App extends React.Component {
  constructor(props){
      super(props)
      this.state = {
      }
  }

  componentDidMount(){
  }
  render () {
      return (
          <div className='App'>
             <FacebookAuth/>
          </div>
      )
  }
}

export default withRouter(App);
